




















import Vue from "vue";
import userManager from "@/services/userManager";

export default Vue.extend({
  data() {
    return {
      currentTab: "",
      tabs: {
        index: {
          label: "Index",
        },
        total: {
          label: "Tổng thống kê",
          condition() {
            return userManager.checkRole(["t", "ktt", "td"]);
          },
        },
        truck_xnk: {
          label: "DS XNK",
          condition() {
            return userManager.checkRole(["t", "xnk"]);
          },
        },
        customers: {
          label: "DS Khách hàng",
          condition() {
            return userManager.checkRole(["t", "ktt", "td", "kd", "tpkd", "gdkd"]);
          },
        },
        new_customers: {
          label: "Khách hàng mới",
          condition() {
            return userManager.checkRole(["t", "ktt", "kt", "mkt"]);
          },
        },
        packages: {
          label: "Lợi nhuận đơn",
          condition() {
            return userManager.checkRole(["t", "ktt", "td"]);
          },
        },
        trucks: {
          label: "Lợi nhuận xe",
          condition() {
            return userManager.checkRole(["t", "ktt", "td"]);
          },
        },
        user_kt: {
          label: "DSTK Kế toán",
          condition() {
            return userManager.checkRole(["t", "ktt", "td"]);
          },
        },
        user_kd: {
          label: "DSTK Kinh doanh",
          condition() {
            return userManager.checkRole(["t", "ktt", "td", "kd"]);
          },
        },
        user_tpkd: {
          label: "DSTK TPKD",
          condition() {
            return userManager.checkRole(["t", "kt", "td", "tpkd", "gdkd"]);
          },
        },
        user_htkd: {
          label: "DSTK HT Kinh doanh",
          condition() {
            return userManager.checkRole(["t", "ktt", "td", "htkd"]);
          },
        },
        user_gd: {
          label: "DSTK Giao dịch",
          condition() {
            return userManager.checkRole(["t", "ktt", "td", "gd"]);
          },
        },
      },
    };
  },
  async created() {
    const pathItems = this.$route.path.split("/");
    this.tab = pathItems[4];
  },
});
